import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";

const classNamePrefix = "only-text-on-light-purple-background";

const OnlyTextOnLightPurpleBackground = () => {
  return (
    <div className={classNamePrefix}>
      <MaxWidthFrame className={`${classNamePrefix}-content-container`}>
        <p>
          지금, 위샵 AI와 함께 더 나은
          <br />
          <strong>비주얼 콘텐츠를 만들어보세요.</strong>
          <br />
          시간과 비용을 절약하면서도 최고의 퀄리티를
          <br />
          자랑하는 이미지를 통해 <strong>당신의 제품을</strong>
          <br />
          <strong>더욱 매력적</strong>으로 표현할 수 있습니다.
        </p>
      </MaxWidthFrame>
    </div>
  );
};

export default OnlyTextOnLightPurpleBackground;
