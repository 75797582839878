import PhotoShower from "components/PhotoShower";
import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";
import {
  DESKTOP_MAN_LIST,
  DESKTOP_MAN_THUMBNAIL_LIST,
  DESKTOP_WOMAN_LIST,
  DESKTOP_WOMAN_THUMBNAIL_LIST,
  MOBILE_MAN_LIST,
  MOBILE_MAN_THUMBNAIL_LIST,
  MOBILE_WOMAN_LIST,
  MOBILE_WOMAN_THUMBNAIL_LIST,
} from "components/PhotoShower/data";
import GradientTitle from "../../assets/images/desktop-show-select-photo/gradient-title.png";
import { mobileScreen } from "variables/screenWidth";

interface IShowSelectPhotoProps {
  windowWidth: number;
}

const classNamePrefix = "show-select-photo";

const ShowSelectPhoto: React.FC<IShowSelectPhotoProps> = ({ windowWidth }) => {
  return (
    <div className={classNamePrefix}>
      <MaxWidthFrame className="select-photo-container">
        {
          windowWidth > mobileScreen && (
            <div className="update-image-container">
              <img src={GradientTitle} alt="1분만에 완성되는 고퀄리티 이미지" />
            </div>
          )
        }
        <div className="photo-shower-container">
          <PhotoShower
            originalFile={
              windowWidth > 375 ? DESKTOP_WOMAN_LIST : MOBILE_WOMAN_LIST
            }
            thumbnail={
              windowWidth > 375
                ? DESKTOP_WOMAN_THUMBNAIL_LIST
                : MOBILE_WOMAN_THUMBNAIL_LIST
            }
            windowWidth={windowWidth}
          />
          <PhotoShower
            originalFile={
              windowWidth > 375 ? DESKTOP_MAN_LIST : MOBILE_MAN_LIST
            }
            thumbnail={
              windowWidth > 375
                ? DESKTOP_MAN_THUMBNAIL_LIST
                : MOBILE_MAN_THUMBNAIL_LIST
            }
            windowWidth={windowWidth}
          />
        </div>
        <div className="text-container">
          <h4>
            <span>위샵 AI</span>는 마네킹 사진이나 일반인 사진을 <br />
            사용하여 브랜드의 전문 모델을 구현할 수 있고,
          </h4>
        </div>
      </MaxWidthFrame>
    </div>
  );
};

export default ShowSelectPhoto;
