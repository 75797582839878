import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import "./App.scss";
import CustomHeader from "components/CustomHeader";
import FrontBanner from "tabs/FrontBanner";
import ShowSelectPhoto from "tabs/ShowSelectPhoto";
import PersonSample from "tabs/PersonSample";
import ImageSwiper from "tabs/ImageSwiper";
import OnlyTextonBlackBackground from "tabs/OnlyTextOnBlackBackground";
import HowToUse from "tabs/HowToUse";
import OnlyTextOnLightPurpleBackground from "tabs/OnlyTextOnLightPurpleBackground";
import EventParticipation from "tabs/EventParticipation";
import { SuccessModal } from "modals";
import DimBackground from "components/DimBackground";

function App() {
  const lastSectionRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window?.innerWidth);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resizee", handleResize);
    };
  }, []);

  const handleClickScrollToLastSection = () => {
    lastSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleShowSuccessModal = () => {
    setIsSuccess(true);
  };

  const handleClickCloseSuccessModal = () => {
    setIsSuccess(false);
  };

  return (
    <div className="App">
      <CustomHeader buttonAction={handleClickScrollToLastSection} />
      <FrontBanner />
      <ShowSelectPhoto windowWidth={windowWidth} />
      <PersonSample windowWidth={windowWidth} />
      <ImageSwiper windowWidth={windowWidth} />
      <OnlyTextonBlackBackground />
      <HowToUse windowWidth={windowWidth} />
      <OnlyTextOnLightPurpleBackground />
      <EventParticipation
        refProp={lastSectionRef}
        activeSuccessModal={handleShowSuccessModal}
      />

      <DimBackground
        active={isSuccess}
        backgroundClickEvent={handleClickCloseSuccessModal}
      />
      <SuccessModal
        isSuccess={isSuccess}
        closeEvent={handleClickCloseSuccessModal}
      />
    </div>
  );
}

export default App;
