import "./styles.scss";

interface IDimBackgroundProps {
  active: boolean;
  className?: string;
  children?: React.ReactNode;
  backgroundClickEvent?: () => void;
}

const classNamePrefix = "dim-background";

const DimBackground: React.FC<IDimBackgroundProps> = ({
  active,
  className,
  children,
  backgroundClickEvent,
}) => {
  const classList = className
    ? `${classNamePrefix} ${className}`
    : classNamePrefix;

  return (
    <div
      className={active ? classList : `${classList} hide`}
      onClick={backgroundClickEvent}
    >
      {children}
    </div>
  );
};

export default DimBackground;
