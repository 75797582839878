import "./styles.scss";
import CustomModal from "components/CustomModal";

interface ISuccessModalProps {
  isSuccess: boolean;
  closeEvent: () => void;
}

const classNamePrefix = "success-modal";

export const SuccessModal: React.FC<ISuccessModalProps> = ({
  isSuccess,
  closeEvent,
}) => {
  if (!isSuccess) return null;

  return (
    <CustomModal className={classNamePrefix}>
      <div className="modal-info-container">
        <h4>이벤트 참여 완료!</h4>
        <p>
          순차적으로 1000 크레딧을 지급해 드릴 예정이며,
          <br />
          <span>신청량에 따라 최대 2~3일 소요될 수 있습니다</span>
        </p>
      </div>
      <div className="confirm-button" onClick={closeEvent}>
        <p>확인</p>
      </div>
    </CustomModal>
  );
};
