// desktop images

import FirstWoman from "../../../assets/images/person-woman/Yvette 8.png";
import SecondWoman from "../../../assets/images/person-woman/Yvette 2.png";
import ThirdWoman from "../../../assets/images/person-woman/Yvette 4.png";
import FourthWoman from "../../../assets/images/person-woman/Yvette 7.png";
import FifthWoman from "../../../assets/images/person-woman/Yvette 6.png";
import SixthWoman from "../../../assets/images/person-woman/Yvette 3.png";
import SeventhWoman from "../../../assets/images/person-woman/Yvette 5.png";

import FirstMan from "../../../assets/images/person-man/desktop-man-1.png";
import SecondMan from "../../../assets/images/person-man/desktop-man-2.png";
import ThirdMan from "../../../assets/images/person-man/desktop-man-3.png";
import FourthMan from "../../../assets/images/person-man/desktop-man-4.png";
import Fifthman from "../../../assets/images/person-man/desktop-man-5.png";
import SixthMan from "../../../assets/images/person-man/desktop-man-6.png";
import SeventhWMan from "../../../assets/images/person-man/desktop-man-7.png";

import FirstWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-1.png";
import SecondWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-2.png";
import ThirdWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-3.png";
import FourthWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-4.png";
import FifthWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-5.png";
import SixthWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-6.png";
import SeventhWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-7.png";

import FirstManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-1.png";
import SecondManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-2.png";
import ThirdManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-3.png";
import FourthManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-4.png";
import FifthManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-5.png";
import SixthManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-6.png";
import SeventhManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-7.png";

// mobile images
import MobileFirstWoman from "../../../assets/images/mobile-person-woman/Yvette 8.png";
import MobileSecondWoman from "../../../assets/images/mobile-person-woman/Yvette 2.png";
import MobileThirdWoman from "../../../assets/images/mobile-person-woman/Yvette 4.png";
import MobileFourthWoman from "../../../assets/images/mobile-person-woman/Yvette 7.png";
import MobileFifthWoman from "../../../assets/images/mobile-person-woman/Yvette 6.png";
import MobileSixthWoman from "../../../assets/images/mobile-person-woman/Yvette 3.png";
import MobileSeventhßWoman from "../../../assets/images/mobile-person-woman/Yvette 5.png";

import MobileFirstWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-1.png";
import MobileSecondWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-2.png";
import MobileThirdWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-3.png";
import MobileFourthWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-4.png";
import MobileFifthWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-5.png";
import MobileSixthWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-6.png";
import MobileSeventhWomanThumbnail from "../../../assets/images/mobile-person-woman-thumbnail/woman-7.png";

import MobileFirstMan from "../../../assets/images/mobile-person-man/man-1.png";
import MobileSecondMan from "../../../assets/images/mobile-person-man/man-2.png";
import MobileThirdMan from "../../../assets/images/mobile-person-man/man-3.png";
import MobileFourthMan from "../../../assets/images/mobile-person-man/man-4.png";
import MobileFifthMan from "../../../assets/images/mobile-person-man/man-5.png";
import MobileSixthMan from "../../../assets/images/mobile-person-man/man-6.png";
import MobileSeventhMan from "../../../assets/images/mobile-person-man/man-7.png";

import MobileFirstManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-1.png";
import MobileSecondManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-2.png";
import MobileThirdManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-3.png";
import MobileFourthManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-4.png";
import MobileFifthManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-5.png";
import MobileSixthManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-6.png";
import MobileSeventhManThumbnail from "../../../assets/images/mobile-person-man-thumbnail/man-7.png";

export const DESKTOP_WOMAN_LIST = [
  FirstWoman,
  SecondWoman,
  ThirdWoman,
  FourthWoman,
  FifthWoman,
  SixthWoman,
  SeventhWoman,
];

export const DESKTOP_MAN_LIST = [
  FirstMan,
  SecondMan,
  ThirdMan,
  FourthMan,
  Fifthman,
  SixthMan,
  SeventhWMan,
];

export const DESKTOP_WOMAN_THUMBNAIL_LIST = [
  FirstWomanThumbnail,
  SecondWomanThumbnail,
  ThirdWomanThumbnail,
  FourthWomanThumbnail,
  FifthWomanThumbnail,
  SixthWomanThumbnail,
  SeventhWomanThumbnail,
];

export const DESKTOP_MAN_THUMBNAIL_LIST = [
  FirstManThumbnail,
  SecondManThumbnail,
  ThirdManThumbnail,
  FourthManThumbnail,
  FifthManThumbnail,
  SixthManThumbnail,
  SeventhManThumbnail,
];

export const MOBILE_WOMAN_LIST = [
  MobileFirstWoman,
  MobileSecondWoman,
  MobileThirdWoman,
  MobileFourthWoman,
  MobileFifthWoman,
  MobileSixthWoman,
  MobileSeventhßWoman,
];

export const MOBILE_WOMAN_THUMBNAIL_LIST = [
  MobileFirstWomanThumbnail,
  MobileSecondWomanThumbnail,
  MobileThirdWomanThumbnail,
  MobileFourthWomanThumbnail,
  MobileFifthWomanThumbnail,
  MobileSixthWomanThumbnail,
  MobileSeventhWomanThumbnail,
];

export const MOBILE_MAN_LIST = [
  MobileFirstMan,
  MobileSecondMan,
  MobileThirdMan,
  MobileFourthMan,
  MobileFifthMan,
  MobileSixthMan,
  MobileSeventhMan,
];

export const MOBILE_MAN_THUMBNAIL_LIST = [
  MobileFirstManThumbnail,
  MobileSecondManThumbnail,
  MobileThirdManThumbnail,
  MobileFourthManThumbnail,
  MobileFifthManThumbnail,
  MobileSixthManThumbnail,
  MobileSeventhManThumbnail,
];
