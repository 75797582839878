import "./styles.scss";

interface ICustomModalProps {
  children?: React.ReactNode;
  className?: string;
}

const classNamePrefix = "custom-modal";

const CustomModal: React.FC<ICustomModalProps> = ({ children, className }) => {
  const classList = className
    ? `${classNamePrefix} ${className}`
    : classNamePrefix;

  return <div className={classList}>{children}</div>;
};

export default CustomModal;
