import MaxWidthFrame from "components/MaxWidthFrame";
import { ReactComponent as MainLogo } from "../../assets/images/weshop-main-logo.svg";
import "./styles.scss";
// import MoveBottom from "../../assets/images/mobile-custom-header/move-bottom.png";
import MoveBottom from "../../assets/images/mobile-custom-header/move-bottom.svg";
// import MobileMainLogo from "../../assets/images/mobile-custom-header/logo.png";
import MobileMainLogo from "../../assets/images/mobile-custom-header/logo.svg";

interface ICustomHeaderProps {
  buttonAction: () => void;
}

const classNamePrefix = "custom-header";

const CustomHeader: React.FC<ICustomHeaderProps> = ({ buttonAction }) => {
  const moveWeshopHome = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    window.location.href = "https://kr.weshop.ai/";
  };
  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <header className={classNamePrefix}>
      <MaxWidthFrame className="header-container">
        <div className="logo-container" onClick={refreshPage}>
          <h1 className="hide">WeShop</h1>
          <MainLogo className="main-logo" />
        </div>

        <div className="logo-container update-mobile" onClick={refreshPage}>
          <img src={MobileMainLogo} alt="WeShop" />
        </div>

        <nav>
          <span onClick={moveWeshopHome}>위샵 홈</span>
          <button type="button" onClick={buttonAction}>
            100장 무료체험 신청
          </button>
        </nav>

        <nav className="update-mobile">
          <img src={MoveBottom} alt="100장 무료체험 신청" onClick={buttonAction}/>
        </nav>
      </MaxWidthFrame>
    </header>
  );
};

export default CustomHeader;
