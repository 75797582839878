import "./styles.scss";
import { useEffect, useState } from "react";
import { Swiper as SwiperType } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";

interface IPhotoShowerProps {
  originalFile?: any[];
  thumbnail?: any[];
  className?: string;
  windowWidth?: number;
}

const classNamePrefix = "photo-shower";

const PhotoShower: React.FC<IPhotoShowerProps> = ({
  originalFile,
  thumbnail,
  className,
  windowWidth,
}) => {
  const [selectPhotoIndex, setSelectPhotoIndex] = useState<number>(0);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const [mainSwiper, setMainSwiper] = useState<SwiperType | null>(null);

  // const handleClickThumbnail = (index: number) => {
  //   setSelectPhotoIndex(index);
  // };

  // useEffect(() => {
  //   // console.log(`thubmSwiper: ${}`)
  //   console.dir(thumbsSwiper);
  // }, [thumbsSwiper]);

  const classNameList = className
    ? `${classNamePrefix} ${className}`
    : classNamePrefix;

  return (
    <div className={classNameList}>
      <div className={windowWidth && windowWidth <= 393 ? "selected-item-container update-mobile" : "selected-item-container"}>
        {/* 정적인 하나의 이미지 */}
        {/* {originalFile && originalFile?.length > 0 && (

          <img
            src={originalFile[selectPhotoIndex]}
            alt=""
            className="selected-item"
          />
        )} */}
        {/* 스와이프로 위치 이동이 가능한 이미지 */}
        <Swiper
          onSwiper={setMainSwiper}
          navigation
          modules={[Navigation, Thumbs]}
          onSlideChange={(swiper: any) =>
            setSelectPhotoIndex(swiper.activeIndex)
          }
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          loop
          className="detail-photo"
        >
          {originalFile?.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={image} alt={`Slide ${index}`} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="thumbnail-container">
        {/* 터치해서 이동하는 네비게이션 */}
        {/* {thumbnail &&
          thumbnail?.map((item, index) => {
            const clickEvent = () => {
              handleClickThumbnail(index);
            };

            return (
              <img
                src={item}
                alt=""
                key={index}
                onClick={clickEvent}
                // className="thumbnail-item"
                className={
                  selectPhotoIndex === index
                    ? "thumbnail-item selected"
                    : "thumbnail-item"
                }
              />                                 
            );
          })} */}
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={windowWidth && windowWidth > 375 ? 4 : 2}
          slidesPerView={7}
          freeMode={true}
          watchSlidesProgress={true}
          style={{ width: "100%" }}
          allowTouchMove={false}
          modules={[Navigation, Thumbs]}
        >
          {thumbnail?.map((slide, index) => (
            <SwiperSlide
              key={index}
              className={
                selectPhotoIndex === index
                  ? `thumbnail-item selected`
                  : `thumbnail-item`
              }
              // className="thumbnail-item"
              style={{
                width: windowWidth && windowWidth > 375 ? 62 : 52,
                // height: windowWidth && windowWidth > 375 ? 83 : 69,
                //     // border:
                //     //   selectPhotoIndex === index ? "1px solid #7530FE" : "none",
                //     // boxSizing: "border-box",
              }}
            >
              <img
                src={slide}
                alt={`Thumbnail ${index}`}
                style={{
                  cursor: "pointer",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PhotoShower;
