import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";
import { ReactComponent as NextStepArrow } from "../../assets/images/desktop-how-to-use-image/next-step-arrow.svg";
import { ReactComponent as NextStepArrowMobile } from "../../assets/images/mobile-how-to-use-image/next-step-arrow.svg";
import Step1 from "../../assets/images/desktop-how-to-use-image/step1.png";
import Step2First from "../../assets/images/desktop-how-to-use-image/step2-1.png";
import Step2Second from "../../assets/images/desktop-how-to-use-image/step2-2.png";
import Step3 from "../../assets/images/desktop-how-to-use-image/step3.png";
import FinalArrow from "../../assets/images/desktop-how-to-use-image/final-arrow.png";
// import FinalArrowMobile from "../../assets/images/mobile-how-to-use-image/final-arrow.png";
import FinalArrowMobile from "../../assets/images/mobile-how-to-use-image/final-arrow.svg";
import ResultBefore from "../../assets/images/desktop-how-to-use-image/result-before.png";
import ResultAfter from "../../assets/images/desktop-how-to-use-image/result-after.png";
import TitleBackground from "../../assets/images/desktop-how-to-use-image/title-image.png";
import { ReactComponent as ResultTitle } from "../../assets/images/desktop-how-to-use-image/result-title.svg";
import { mobileScreen } from "variables/screenWidth";

interface IHowToUseProps {
  windowWidth: number;
}

const classNamePrefix = "how-to-use";

const HowToUse: React.FC<IHowToUseProps> = ({ windowWidth }) => {
  const togglePlatfromNextStepArrow = () => {
    let output = null;

    if (windowWidth > mobileScreen) {
      output = <NextStepArrow className="next-step-arrow" />;
    } else {
      output = <NextStepArrowMobile className="next-step-arrow isMobile" />;
    }
    return output;
  };

  const togglePlatfromFinalArrow = () => {
    let output = null;

    if (windowWidth > mobileScreen) {
      output = <img src={FinalArrow} alt="" />;
    } else {
      output = <img src={FinalArrowMobile} alt="" />;
    }
    return output;
  };
  return (
    <div className={classNamePrefix}>
      <MaxWidthFrame className={`${classNamePrefix}-use-container`}>
        <div className="title-container">
          <img src={TitleBackground} alt="" />
          <h4>
            이용 방법 <span>(How To Use)</span>
          </h4>
        </div>
        <div className="step-container">
          <div className="steps step-1">
            <p>
              <em>1</em> <strong>이미지를 업로드</strong>하고
            </p>
            <img src={Step1} alt="" />
          </div>
          {togglePlatfromNextStepArrow()}
          {/* <NextStepArrow className="next-step-arrow" /> */}

          <div className="steps step-2">
            <p>
              <em>2</em> <strong>모델과 촬영 로케이션</strong>(선택사항)을 선택
            </p>
            <div className="image-container">
              <img src={Step2First} alt="" />
              <img src={Step2Second} alt="" />
            </div>
          </div>
          {togglePlatfromNextStepArrow()}
          {/* <NextStepArrow className="next-step-arrow" /> */}
          <div className="steps step-3">
            <p>
              <em>3</em> 간단한 <strong>프롬프트를 입력</strong>(선택사항)
              <br />
              하고 <strong>생성하기</strong>만 누르면
            </p>
            <img src={Step3} alt="" />
          </div>
          <div className="final-arrow-container">
            {/* <img src={FinalArrow} alt="" /> */}
            {togglePlatfromFinalArrow()}
          </div>
          <div className="result">
            <p className="hide">나만의 멋진 모델이 생성됩니다.</p>
            <ResultTitle className="result-title" />
            <div className="result-image-container before">
              <img className="result-image" src={ResultBefore} alt="" />
            </div>
            <div className="result-image-container after">
              <img className="result-iamge" src={ResultAfter} alt="" />
            </div>
          </div>
        </div>
      </MaxWidthFrame>
    </div>
  );
};

export default HowToUse;
