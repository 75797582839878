import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";
import TextBackgroundDecoration from "../../assets/images/desktop-person-sample/text-background-decoration.png";
import Sample1 from "../../assets/images/desktop-person-sample/sample-1.png";
import Sample2 from "../../assets/images/desktop-person-sample/sample-2.png";
import Sample1Mobile from "../../assets/images/mobile-person-example/sample1.png";
import Sample2Mobile from "../../assets/images/mobile-person-example/sample2.png";

interface IPersonSampleProps {
  windowWidth: number;
}

const classNamePrefix = "person-sample";

const PersonSample: React.FC<IPersonSampleProps> = ({ windowWidth }) => {
  const togglePlatformSample1 = () => {
    let output = null;

    if (windowWidth > 375) {
      output = <img src={Sample1} alt="" />;
    } else {
      output = <img src={Sample1Mobile} alt="" />;
    }
    return output;
  };
  const togglePlatformSample2 = () => {
    let output = null;

    if (windowWidth > 375) {
      output = <img src={Sample2} alt="" />;
    } else {
      output = <img src={Sample2Mobile} alt="" />;
    }
    return output;
  };

  return (
    <div className={classNamePrefix}>
      <MaxWidthFrame className={`${classNamePrefix}-container`}>
        <div className="bold-text-container">
          <div className="position-container">
            <img
              src={TextBackgroundDecoration}
              alt=""
              className="background-decoration"
            />
            <h4>
              모델 고용 비용 및 스튜디오 대관 비용을
              <br />
              <span>크게 절감할 수 있습니다.</span>
            </h4>
          </div>
        </div>
        <div className="compare-sample-container">
          <div className="left-content-area">{togglePlatformSample1()}</div>
          <div className="right-content-area">{togglePlatformSample2()}</div>
        </div>
      </MaxWidthFrame>
    </div>
  );
};

export default PersonSample;
