import "./styles.scss";
import "./update-mobile.scss";
// import { ReactComponent as PurpleM } from "../../assets/images/desktop-animation-image/desktop-purple-m.svg";
import DesktopPurpleM from "../../assets/images/desktop-animation-image/desktop-purple-m.png";
import MobilePurpleM from "../../assets/images/mobile-animation-image/purple-m.png";
// import textBars from "../../assets/images/three-text-bar.png";
import textBars from "../../assets/images/desktop-front-banner/three-text-bar.png";
import MobileThreeTextBar from "../../assets/images/mobile-animation-image/mobile-three-text-bar.png";
import WhiteStar from "../../assets/images/desktop-animation-image/desktop-star.png";
import GreenSticker from "../../assets/images/desktop-animation-image/desktop-green-sticker.png";
import MobileWhiteStar from "../../assets/images/mobile-animation-image/star.png";
import MobileGreenSticker from "../../assets/images/mobile-animation-image/green-sticker.png";
import PurpleTwistDonut from "../../assets/images/desktop-animation-image/purple-twist-donut.png";
import MaxWidthFrame from "components/MaxWidthFrame";

// import UpdateMobileEventInfo from "../../assets/images/mobile-front-banner/event-info.png";
import UpdateMobileEventInfo from "../../assets/images/mobile-front-banner/event-info.svg";
// import UpdateMobileHeadlineCapture from "../../assets/images/mobile-front-banner/mobile-headline-capture.png"
import UpdateMobileHeadlineCapture from "../../assets/images/mobile-front-banner/mobile-headline-capture.svg"
// import UpdateMobileTitle from "../../assets/images/mobile-front-banner/mobile-title.png";
import UpdateMobileTitle from "../../assets/images/mobile-front-banner/mobile-title.svg";

import FrontBannerCapture from "../../assets/images/desktop-front-banner/front-banner-capture.svg";
import DifferentImage from "../../assets/images/desktop-front-banner/different-image.svg";
import OneMonthEvent from "../../assets/images/desktop-front-banner/one-month-event.svg";

interface IFrontBannerProps {}

const classNamePrefix = "front-banner";

const FrontBanner: React.FC<IFrontBannerProps> = () => {
  return (
    <div className={classNamePrefix}>
      {/* 기존버전 데스크탑~모바일 */}
      <MaxWidthFrame className="banner-container">

        {/* 추가 데스크탑 디자인 변경 */}
        <div className="changed-animation-container">
          <img src={DifferentImage} alt="글로벌 빅데이터를 기반으로 한 생성형 AI 기술로 차별화된 이미지를 만들어보세요." className="different-image"/>
          
          <div className="animation-box">
            <div className="one-month-event">
              <img src={OneMonthEvent} alt="한달간 이벤트를 진행하며, 이벤트에 참여하시는 분들께는 이미지 100장을 생성할 수 있는 1,000 크레딧을 드립니다"/>
              <img src={WhiteStar} alt="" className="update-icon update-white-star" />
              <img src={GreenSticker} alt="" className="update-icon update-green-sticker" />
            </div>
          </div>
        </div>
        {/* 가로 <-> 세로 정렬 변환 블록 */}
        {/* <img src={FrontBannerCapture} alt="글로벌 빅데이터를 기반으로 한 생성형 AI 기술로 차별화된 이미지를 만들어보세요. 한달간 이벤트를 진행하며, 이벤트에 참여하시는 분들께는 이미지 100장을 생성할 수 있는 1,000 크레딧을 드립니다." /> */}
        {/* <div className="switch-align-text-container">
          <div className="left-area-container">
            <img className="purple-m-icon" src={DesktopPurpleM} alt="" />
            <img className="mobile-purple-m-icon" src={MobilePurpleM} alt="" />
            <h2>
              글로벌 빅데이터를 기반으로 한<br />
              생성형 AI 기술로 <br />
              <span>차별화된 이미지를 만들어보세요.</span>
            </h2>
          </div>
          <div className="right-area-container">
            <div className="desktop-animation-container">
              <img className="desktop-white-star" src={WhiteStar} alt="" />
              <img
                className="desktop-green-sticker"
                src={GreenSticker}
                alt=""
              />
              <img className="desktop-text-bars" src={textBars} alt="" />
            </div>
            <div className="mobile-text-animation">
              <img
                className="mobile-three-text-bars"
                src={MobileThreeTextBar}
                alt=""
              />
              <img className="mobile-white-star" src={MobileWhiteStar} alt="" />
              <img
                className="mobile-green-sticker"
                src={MobileGreenSticker}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="only-text-container">
          <div className="position-container">
            <p className="only-show-mobile-text">
              글로벌이 선택한 위샵 AI는 <strong>PC 전용 서비스</strong>입니다.
            </p>
            <p className="event-text">
              7월 한달간 이벤트를 진행하며, <br />
              이벤트에 참여하시는 분들께는{" "}
              <span className="soft-pink-text">이미지 100장</span>을
              <br />
              생성할 수 있는{" "}
              <span className="soft-pink-text">1,000 크레딧</span>을 드립니다
            </p>
            <img className="donut-icon" src={PurpleTwistDonut} alt="" />
          </div>
        </div> */}
      </MaxWidthFrame>
      {/* 신규버전 모바일만 작성 */}
      <MaxWidthFrame className="banner-container new-mobile-version">
        <div className="event-text-container">
          {/* <p>
            글로벌이 선택한 위샵 AI는  PC 전용 서비스 입니다. 한달간 이벤트를 진행하며, 이벤트에 참여하시는 분들께는 이미지 100장을 생성할 수 있는 1,000 크레딧을 드립니다
          </p> */}
          <img src={UpdateMobileEventInfo} alt="글로벌이 선택한 위샵 AI는  PC 전용 서비스 입니다. 한달간 이벤트를 진행하며, 이벤트에 참여하시는 분들께는 이미지 100장을 생성할 수 있는 1,000 크레딧을 드립니다" />
          <img src={MobileWhiteStar} alt="" className="update-mobile-icon update-mobile-white-star"/>
          <img src={MobileGreenSticker} alt="" className="update-mobile-icon update-mobile-green-star"/>
        </div>
        <div className="headline-container">
          {/* <h4>
            글로벌 빅데이터를 기반으로 한 생성형 AI 기술로 차별화된 이미지를 만들어보세요.
          </h4> */}
          <img src={UpdateMobileHeadlineCapture} alt="글로벌 빅데이터를 기반으로 한 생성형 AI 기술로 차별화된 이미지를 만들어보세요." />
        </div>
        <div className="absolute-image-container">
          {/* <div className="rotate-text-container"> */}
          <p className="rotate-text">1분 만에 완성되는 고퀄리티 이미지</p>
          {/* </div> */}
          {/* <img src={UpdateMobileTitle} alt="1분 만에 완성되는 고퀄리티 이미지" /> */}
        </div>
      </MaxWidthFrame>
    </div>
  );
};

export default FrontBanner;
