import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";
import { ReactComponent as DivisionLine } from "../../assets/images/desktop-image-swiper/division.svg";
import { ReactComponent as MobileDivisionLine } from "../../assets/images/mobile-image-swiper/division.svg";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import SqureImage11 from "../../assets/images/square-in-image/line-1-1.png";
import SqureImage12 from "../../assets/images/square-in-image/line-1-2.png";
import SqureImage13 from "../../assets/images/square-in-image/line-1-3.png";
import SqureImage14 from "../../assets/images/square-in-image/line-1-4.png";
import SqureImage15 from "../../assets/images/square-in-image/line-1-5.png";
import SqureImage21 from "../../assets/images/square-in-image/line-2-1.png";
import SqureImage22 from "../../assets/images/square-in-image/line-2-2.png";
import SqureImage23 from "../../assets/images/square-in-image/line-2-3.png";
import SqureImage24 from "../../assets/images/square-in-image/line-2-4.png";
import SqureImage25 from "../../assets/images/square-in-image/line-2-5.png";

import SqureImage11Mobile from "../../assets/images/square-in-image-mobile/m-line-1-1.png";
import SqureImage12Mobile from "../../assets/images/square-in-image-mobile/m-line-1-2.png";
import SqureImage13Mobile from "../../assets/images/square-in-image-mobile/m-line-1-3.png";
import SqureImage14Mobile from "../../assets/images/square-in-image-mobile/m-line-1-4.png";
import SqureImage15Mobile from "../../assets/images/square-in-image-mobile/m-line-2-5.png";
import SqureImage21Mobile from "../../assets/images/square-in-image-mobile/m-line-2-1.png";
import SqureImage22Mobile from "../../assets/images/square-in-image-mobile/m-line-2-2.png";
import SqureImage23Mobile from "../../assets/images/square-in-image-mobile/m-line-2-3.png";
import SqureImage24Mobile from "../../assets/images/square-in-image-mobile/m-line-2-4.png";
import SqureImage25Mobile from "../../assets/images/square-in-image-mobile/m-line-2-5.png";
import { Navigation } from "swiper/modules";
import { useEffect, useRef } from "react";

import prevIcon from "../..//assets/images/mobile-image-swiper/prev-button.png";
import nextIcon from "../..//assets/images/mobile-image-swiper/next-button.png";


interface IImageSwiperProps {
  windowWidth: number;
}
interface NavigationOptions {
  prevEl?: HTMLElement | null;
  nextEl?: HTMLElement | null;
}

const classNamePrefix = "image-swiper";

const ImageSwiper: React.FC<IImageSwiperProps> = ({ windowWidth }) => {

  const firstPrevRef = useRef<HTMLDivElement>(null);
  const firstNextRef = useRef<HTMLDivElement>(null);
  const secondPrevRef = useRef<HTMLDivElement>(null);
  const secondNextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    const updateNavigation = (swiper: SwiperClass, prevRef: React.RefObject<HTMLDivElement>, nextRef: React.RefObject<HTMLDivElement>) => {
      if (swiper && prevRef.current && nextRef.current) {
        const navigation = swiper.params.navigation;
        if (navigation && typeof navigation !== 'boolean') {
          navigation.prevEl = prevRef.current;
          navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }
      }
    };

    const initSwiperNavigation = (swiperClass: string, prevRef: React.RefObject<HTMLDivElement>, nextRef: React.RefObject<HTMLDivElement>) => {
      const swiperElement = document.querySelector(swiperClass) as HTMLElement & { swiper: SwiperClass };
      if (swiperElement && swiperElement.swiper) {
        updateNavigation(swiperElement.swiper, prevRef, nextRef);
      }
    };

    initSwiperNavigation('.update-first-swiper', firstPrevRef, firstNextRef);
    initSwiperNavigation('.update-second-swiper', secondPrevRef, secondNextRef);
  },[])

  const fisrtDeviceImages = [
    { desktop: SqureImage11, mobile: SqureImage11Mobile },
    { desktop: SqureImage12, mobile: SqureImage12Mobile },
    { desktop: SqureImage13, mobile: SqureImage13Mobile },
    { desktop: SqureImage14, mobile: SqureImage14Mobile },
    { desktop: SqureImage15, mobile: SqureImage15Mobile },
  ];
  const secondDeviceImages = [
    { desktop: SqureImage21, mobile: SqureImage21Mobile },
    { desktop: SqureImage22, mobile: SqureImage22Mobile },
    { desktop: SqureImage23, mobile: SqureImage23Mobile },
    { desktop: SqureImage24, mobile: SqureImage24Mobile },
    { desktop: SqureImage25, mobile: SqureImage25Mobile },
  ];

  return (
    <div className={classNamePrefix}>
      <div className="align-center-text-container">
        <MaxWidthFrame className={`max-width-container`}>
          <div className="division-text-container">
            <p className="align-right">
              뿐만 아니라,
              <br />
              <strong>다양한 상품과 식음료(F&B) 등의 이미지 컷</strong>을<br />
              활용해 화려한 배경을 만들어낼 수 있고
              <br />
              마케팅 및 광고 이미지로 제작할 수 있습니다.
            </p>
            <DivisionLine className="division-line desktop" />
            <MobileDivisionLine className="division-line mobile" />
            <p className="align-left">
              이커머스 판매자들은 제품 상세 페이지에
              <br />
              사용할 수 있는 고품질의 비주얼 콘텐츠를
              <br />
              손쉽게 생성하여, <strong>브랜드의 가치를 높이고</strong>
              <br />
              <strong>매출을 극대화</strong>할 수 있습니다.
            </p>
          </div>
        </MaxWidthFrame>
      </div>
      <div className="swiper-full-width-container">
        <div className="horizontal-swiper-container first-swiper">
          <Swiper
            slidesPerView={5}
            spaceBetween={0}
            loop
            className="mySwiper update-first-swiper"
            navigation
            modules={[Navigation]}
            onSwiper={(swiper: SwiperClass) => {
              const navigation = swiper.params.navigation;
              if (firstPrevRef.current && firstNextRef.current && navigation && typeof navigation !== 'boolean') {
                const navOptions = navigation as NavigationOptions;
                navOptions.prevEl = firstPrevRef.current;
                navOptions.nextEl = firstNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
          >
            {fisrtDeviceImages?.map((item, index) => {
              return (
                <SwiperSlide className="square-slide" key={`top-${index}`}>
                  <img
                    src={windowWidth > 375 ? item?.desktop : item?.mobile}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
            {fisrtDeviceImages?.map((item, index) => {
              return (
                <SwiperSlide className="square-slide" key={`bottom-${index}`}>
                  <img
                    src={windowWidth > 375 ? item?.desktop : item?.mobile}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="update-swiper-navigation-container">
            <div className="navigation-icon update-swiper-prev" ref={firstPrevRef}>
              <img src={prevIcon} alt="이전" />
            </div>
            <div className="navigation-icon update-swiper-next" ref={firstNextRef}>
              <img src={nextIcon} alt="다음" />
            </div>
          </div>
        </div>
        <div className="horizontal-swiper-container second-swiper">
          <Swiper
            slidesPerView={5}
            spaceBetween={0}
            loop
            className="mySwiper update-second-swiper"
            navigation
            modules={[Navigation]}
            onSwiper={(swiper: SwiperClass) => {
              const navigation = swiper.params.navigation;
              if (secondPrevRef.current && secondNextRef.current && navigation && typeof navigation !== 'boolean') {
                const navOptions = navigation as NavigationOptions;
                navOptions.prevEl = secondPrevRef.current;
                navOptions.nextEl = secondNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
          >
            {secondDeviceImages?.map((item, index) => {
              return (
                <SwiperSlide className="square-slide" key={`bottom-${index}`}>
                  <img
                    src={windowWidth > 375 ? item?.desktop : item?.mobile}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
            {secondDeviceImages?.map((item, index) => {
              return (
                <SwiperSlide className="square-slide" key={`bottom-${index}`}>
                  <img
                    src={windowWidth > 375 ? item?.desktop : item?.mobile}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}        
          </Swiper>
          <div className="update-swiper-navigation-container">
            <div className="navigation-icon update-swiper-prev" ref={secondPrevRef}>
              <img src={prevIcon} alt="이전" />
            </div>
            <div className="navigation-icon update-swiper-next" ref={secondNextRef}>
              <img src={nextIcon} alt="다음" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSwiper;
