import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";
import textBackground from "../../assets/images/desktop-only-text-on-black-background/background.png";
// import textCapture from "../../assets/images/mobile-only-text-on-black-background/text-capture.png";
import textCapture from "../../assets/images/mobile-only-text-on-black-background/text-capture.svg";

const classNamePrefix = "only-text-on-black-background";

const OnlyTextonBlackBackground = () => {
  return (
    <div className={classNamePrefix}>
      <MaxWidthFrame
        className={`${classNamePrefix}-black-background-container`}
      >
        <img className="text-background" src={textBackground} alt="" />
        <h4>
          위샵 AI는 사용자 친화적인 인터페이스를
          <br />
          제공하여 <strong>기술적인 지식이 없는</strong>
          <br />
          <strong>판매자들도 쉽게 사용</strong>할 수 있습니다.
        </h4>

        <h4>
          생성된 이미지를 브랜드 이미지와
          <br />
          스타일에 맞게 커스터마이즈할 수 있는
          <br />
          <strong>다양한 옵션을 제공</strong>합니다.
        </h4>
      </MaxWidthFrame>
      <MaxWidthFrame className={`${classNamePrefix}-black-background-container update-mobile`}>
        <img src={textCapture} alt="위샵 AI는 사용자 친화적인 인터페이스를 제공하여 기술적인 지식이 없는 판매자들도 쉽게 사용할 수 있습니다. 생성된 이미지를 브랜드 이미지와 스타일에 맞게 커스터마이즈할 수 있는 다양한 옵션을 제공합니다." />
      </MaxWidthFrame>
    </div>
  );
};

export default OnlyTextonBlackBackground;
