import "./styles.scss";
import MaxWidthFrame from "components/MaxWidthFrame";
import TitleBackground from "../../assets/images/desktop-event-particifition/background.png";
import { useRef, useState } from "react";
import { EmailValidation } from "functions/EmailValidation";
// import PromotionTextCapture from "../../assets/images/mobile-event-participation/promotion-text-capture.png";
import PromotionTextCapture from "../../assets/images/mobile-event-participation/promotion-text-capture.svg";
// import TitleCaputre from "../../assets/images/mobile-event-participation/title-capture.png";
import TitleCaputre from "../../assets/images/mobile-event-participation/title-capture.svg";
import WriteFormCredit from "../../assets/images/mobile-event-participation/write-form-credit.png";
import MobileShowText from "../../assets/images/mobile-event-participation/mobile-show-text.png";

interface IEventParticipationProps {
  refProp: React.RefObject<HTMLDivElement>;
  activeSuccessModal: () => void;
}

interface FormData {
  name: string;
  email: string;
  brand: null | string;
}

const classNamePrefix = "event-participation";

const EventParticipation: React.FC<IEventParticipationProps> = ({
  refProp,
  activeSuccessModal,
}) => {
  const nameInputMessageRef = useRef<HTMLInputElement | null>(null);
  const emailInputMessageRef = useRef<HTMLInputElement | null>(null);
  const [formdata, setFormdata] = useState<FormData>({ name: "", email: "", brand: null });
  const [hasSubmitBefore, setHasSubmitBefore] = useState<boolean>(false);
  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const [nameValidate, setNameValidate] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormdata({ ...formdata, [name]: value });
  };
  const handleNameInputBlur = () => {
    if (formdata?.name && formdata?.name?.length >= 2) {
      setNameValidate(true);
    } else {
      setNameValidate(false);
    }
  };

  const handleEmailInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const userEmail = formdata?.email;
    if (EmailValidation(userEmail)) {
      setEmailValidate(true);
    } else {
      setEmailValidate(false);
    }
  };

  const trySlackMessage = async () => {
    return await new Promise((resolve, reject) => {
      const apiUrl =
        "https://hooks.slack.com/services/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";
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      const body = JSON.stringify({
        text: "이벤트 참여 알림",
        blocks: [
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: "새로운 이벤트 참여가 있습니다.",
            },
          },
          {
            type: "section",
            block_id: "section123",
            fields: [
              {
                type: "mrkdwn",
                text: `*이름*\n${formdata?.name}\n\n*이메일*\n${formdata?.email}\n\n`,
              },
            ],
          },
        ],
      });

      try {
        fetch(apiUrl, {
          method: "POST",
          headers: headers,
          body: body,
        }).then(() => {
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const tryPostUserData = async () => {
    let bodyData:any = {};

    // email과 name은 검증을 거치므로 확정적으로 값을 지정합니다.
    bodyData.email = formdata.email;
    bodyData.name = formdata.name;
    // brand는 선택적 값이므로 조건에 맞을 경우에만 값을 지정합니다.
    if(formdata?.brand){
      bodyData.brand = formdata.brand; 
    }


    fetch(
      "https://asia-northeast3-shop-global-ac31c.cloudfunctions.net/weshop-event",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
        // body: JSON.stringify({
        //   email: formdata.email,
        //   name: formdata.name,
        //   brand: formdata.brand,
        // }),
      }
    );

    if (true) {
      // post가 성공했을때
      activeSuccessModal();
    }
  };

  const handleSubmit = (data: React.FormEvent<HTMLFormElement>) => {
    data.preventDefault();

    // 이메일 유효성 검사를 통과하면 진행
    if (emailValidate && nameValidate) {
      // 1. 사용자 정보 보내기
      tryPostUserData();
      // 2. slack 채널에 안내 메세지 보내기
      trySlackMessage()
        .then(() => {})
        .catch((error) => {
          console.error(
            `trySlackMessage got error\nerrorInfo:\n${JSON.stringify(error)}`
          );
        });
    } else {
      // 유효성 검사에 실패했을 때
      setHasSubmitBefore(true);
    }
  };

  return (
    <div className={classNamePrefix} ref={refProp}>
      <MaxWidthFrame className={`${classNamePrefix}-container`}>
        <div className="promotion">
          <h4>
            📍위샵 AI 신규 가입자를 위한 특별 <span>프로모션</span>
          </h4>
          <p>
            지금 위샵 AI에 가입하시면 <strong>65,000원 상당인</strong>
            <br />
            <strong>1,000 크레딧(약 100장 생성)을 선물로 지급</strong>
            해드립니다.
          </p>
        </div>
        <div className="promotion update-mobile">
          <h4>
            📍위샵 AI 신규 가입자를 위한 특별 <span>프로모션</span>
          </h4>
          <p>
            지금 위샵 AI에 가입하시면 <strong>65,000원 상당인</strong>
            <br />
            <strong>1,000 크레딧(약 100장 생성)을 선물로 지급</strong>
            해드립니다.
          </p>
          {/* <img src={PromotionTextCapture} alt="위샵 AI 신규 가입자를 위한 특별 프로모션, 지금 위샵 AI에 가입하시면 65,000원 상당인 1,000 크레딧(약 100장 생성)을 선물로 지급해드립니다." /> */}
        </div>

        <div className="method-container">
          {/* <MethodBackgroundMain className="main-background" /> */}
          {/* <MethodBackgroundSub className="sub-background" /> */}
          <p className="method">참여방법</p>
          <img src={TitleBackground} alt="" />
        </div>

        <div className="method-container update-mobile">
          {/* <img src={TitleCaputre} alt="참여방법" /> */}
          {/* <p className="method">참여방법</p> */}
          <img src={TitleBackground} alt="참여방법" />
        </div>

        <div className="credit-container">
          <p className="credit-title">
            아래 양식을 작성하여 1,000 크레딧의 기회를 잡으세요!
          </p>
          {/* <img className="credit-title update-mobile" src={WriteFormCredit} alt="아래 양식을 작성하여 1,000 크레딧의 기회를 잡으세요!" /> */}
        </div>

        <form action="" id="event-form" onSubmit={handleSubmit}>
          <div className="horizontal-align-container">
            <div className="form-layout">
              <div className="input-field">
                <div className="input-set">
                  <label>이름</label>
                  <input
                    type="text"
                    name="name"
                    id="user-name"
                    placeholder="이름을 입력해주세요."
                    onChange={handleChange}
                    onBlur={handleNameInputBlur}
                    autoComplete={"off"}
                  />
                  <span
                    ref={nameInputMessageRef}
                    className={
                      hasSubmitBefore && !nameValidate
                        ? "warning-message checkAfter"
                        : "warning-message"
                    }
                  >
                    이름은 두 글자 이상 입력해주세요.
                  </span>
                </div>

                <div className="input-set">
                  <label>이메일</label>
                  <input
                    type="text"
                    name="email"
                    id="user-email"
                    placeholder="위샵에 가입한 이메일을 입력해주세요."
                    onChange={handleChange}
                    onBlur={handleEmailInputBlur}
                    autoComplete={"off"}
                  />
                  <span
                    ref={emailInputMessageRef}
                    className={
                      hasSubmitBefore && !emailValidate
                        ? "warning-message checkAfter"
                        : "warning-message"
                    }
                  >
                    이메일 형식으로 입력해주세요.
                  </span>
                </div>

                <div className="input-set">
                  <label>브랜드명 OR 브랜드 URL (선택사항)</label>
                  <input
                    type="text"
                    name="brand"
                    id="brand-info"
                    placeholder="정보를 입력해주세요."
                    onChange={handleChange}
                    onBlur={handleEmailInputBlur}
                    autoComplete={"off"}
                  />
                  {/* <span
                    ref={emailInputMessageRef}
                    className={
                      hasSubmitBefore && !emailValidate
                        ? "warning-message checkAfter"
                        : "warning-message"
                    }
                  >
                    이메일 형식으로 입력해주세요.
                  </span> */}
                </div>
              </div>
              <button type="submit">이벤트 참여하기</button>
            </div>
          </div>
        </form>
        <div className="show-mobile">
          <p className="guide-desktop">
            위샵 AI는 현재 PC/태블릿으로 이용 가능한
            <br />
            서비스입니다. <span>PC 또는 태블릿으로 접속</span> 하시면
            <br /> 더 다양한 서비스를 이용하실 수 있습니다.
          </p>

          {/* <p className="guide-desktop update-mobile">
            <img src={MobileShowText} alt="위샵 AI는 현재 PC/태블릿으로 이용 가능한 서비스입니다. PC 또는 태블릿으로 접속 하시면 더 다양한 서비스를 이용하실 수 있습니다." />
          </p> */}

          <nav>
            <a href="https://kr.weshop.ai/">위샵 AI 공식 홈페이지로 이동</a>
            <a href="https://kr-blog.weshop.ai/">위샵 AI 블로그로 이동</a>
          </nav>
        </div>
      </MaxWidthFrame>
    </div>
  );
};

export default EventParticipation;
