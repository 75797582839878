import { CSSProperties } from "react";
import "./styles.scss";

interface IMaxWidthFrameProps {
  style?: CSSProperties;
  children?: React.ReactNode;
  className?: string;
}

const classNamePrefix = "max-width-frame";

const MaxWidthFrame: React.FC<IMaxWidthFrameProps> = ({
  children,
  style,
  className,
}) => {
  const classList = className
    ? `${classNamePrefix} ${className}`
    : classNamePrefix;

  return (
    <div className={classList} style={style}>
      {children}
    </div>
  );
};

export default MaxWidthFrame;
